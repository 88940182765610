
import BasicPageLayout from '@/views/basic-page-layout.vue'

export default {
  extends: BasicPageLayout,

  name: 'EasyLanguageView',
  components: {
    BasicPageLayout
  }
}
